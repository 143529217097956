<template>
  <div>
    <div class="text-right pb-2"></div>
    <b-row class="d-flex justify-content-between">
      <b-col
        md=""
        class="d-flex align-items-center bg-colorWhite w-100 py-2 px-2 mb-1 mb-sm-0"
        style="border-radius: 20px"
      >
        <div class="w-50">
          <h1 style="font-size: 2rem" class="text-colorBlue font-weight-bolder">
            400
          </h1>
          <div>
            <h4
              class="text-colorBlack font-weight-bolder"
              style="font-size: 0.9rem"
            >
              Count of Schools
            </h4>
          </div>
        </div>
        <div class="text-right w-50">
          <img
            src="@/assets/images/icons/instructor/dashboard/classes.svg"
            width="50px"
            img-fluid
          />
        </div>
      </b-col>
      <b-col
        md=""
        class="d-flex align-items-center bg-colorWhite w-100 py-2 px-2 ml-0 ml-md-1 mb-1 mb-sm-0"
        style="border-radius: 20px"
      >
        <div class="w-50">
          <h1
            style="font-size: 2rem"
            class="text-colorOrange font-weight-bolder"
          >
            1100
          </h1>
          <div>
            <h4
              class="text-colorBlack font-weight-bolder"
              style="font-size: 0.9rem"
            >
              Count of Colleges
            </h4>
          </div>
        </div>
        <div class="text-right w-50">
          <img
            src="@/assets/images/icons/instructor/dashboard/subjects.svg"
            width="50px"
            img-fluid
          />
        </div>
      </b-col>
      <b-col
        md=""
        class="d-flex align-items-center bg-colorWhite w-100 py-2 px-2 ml-0 ml-md-1 mb-1 mb-sm-0"
        style="border-radius: 20px"
      >
        <div class="w-50">
          <h1
            style="font-size: 2rem"
            class="text-colorYellow font-weight-bolder"
          >
            24
          </h1>

          <div>
            <h5
              class="text-colorBlack font-weight-bolder"
              style="font-size: 0.9rem"
            >
              Count of Universities
            </h5>
          </div>
        </div>
        <div class="text-right w-50">
          <img
            src="@/assets/images/icons/instructor/dashboard/lectures.svg"
            width="50px"
            img-fluid
          />
        </div>
      </b-col>
      <b-col
        md=""
        class="d-flex align-items-center bg-colorWhite w-100 py-2 px-2 ml-0 ml-md-1"
        style="border-radius: 20px"
      >
        <div class="w-50">
          <h1 style="font-size: 2rem" class="text-colorRed font-weight-bolder">
            44
          </h1>
          <div>
            <h4
              class="text-colorBlack font-weight-bolder"
              style="font-size: 0.9rem"
            >
              Total Users
            </h4>
          </div>
        </div>
        <div class="text-right w-50">
          <img
            src="@/assets/images/icons/instructor/dashboard/students.svg"
            width="50px"
            img-fluid
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-between mt-2 h-50">
      <b-col
        md=""
        class="p-0 m-0 bg-colorWhite w-100"
        style="border-radius: 20px"
      >
      </b-col>
      <b-col
        md=""
        class="p-0 m-0 bg-colorWhite w-100"
        style="border-radius: 20px"
      >
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      subjectCode: "",
      grade: 70,
      submitted: 47,
      max: 100,
      modalVisible: false,
      modalVisibleFeedback: false,
      modalVisibleUpload: false,
    };
  },
};
</script>

<style></style>
